export const robotConstants = {
  ROBOT_DETAILS_REQUEST: "ROBOT_DETAILS_REQUEST",
  ROBOT_DETAILS_SUCCESS: "ROBOT_DETAILS_SUCCESS",
  ROBOT_DETAILS_FAIL: "ROBOT_DETAILS_FAIL",

  ROBOT_STATUS_REQUEST: "ROBOT_STATUS_REQUEST",
  ROBOT_STATUS_SUCCESS: "ROBOT_STATUS_SUCCESS",
  ROBOT_STATUS_FAIL: "ROBOT_STATUS_FAIL",

  DESTROY_SESSION: "DESTROY_SESSION",

  CLEAR_ERRORS: "CLEAR_ERRORS",
};
