import React from "react";
import { useState } from "react";
import {
  dropofflocations,
  homelocation,
  MapData,
  pickuplocations,
} from "../Data/MapData";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import { TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import { alpha, styled } from "@mui/material/styles";
import * as yup from "yup";
import { useFormik } from "formik";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useEffect } from "react";
import AddMap from "../subcomponents/AddMap";
import AddIcon from "@mui/icons-material/Add";
import { deleteMap, getmaplist, getMapLocationList } from "../API";
import EditMap from "../subcomponents/EditMap";
import { toast, ToastContainer } from "react-toastify";
import DeleteMap from "../subcomponents/DeleteMap";
import CreateIcon from "@mui/icons-material/Create";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";

function FleetPageMapPage(props) {
  const [googlemap, setgooglemap] = useState();
  const [maplist, setmaplist] = useState();
  const [maplocationlist, setmaplocationlist] = useState();
  const [refresh, serrefresh] = useState(false);
  const [modalopen1, setmodalOpen1] = React.useState(false);
  const [modalopen2, setmodalOpen2] = React.useState(false);
  const [modalopen3, setmodalOpen3] = React.useState(false);

  const token = localStorage.getItem("token");
  const fleetId = localStorage.getItem("fleetId");
  const handlemaptoggle = () => {
    setgooglemap(!googlemap);
  };

  const changerefresh = () => {
    serrefresh(!refresh);
  };

  let robotassign = "";

  const modalstyle1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "#3D4665",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const modalstyle2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "#3D4665",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const handleOpen1 = () => setmodalOpen1(true);

  const handleClose1 = (reason) => {
    if (reason && reason === "backdropClick") return;
    setmodalOpen1(false);
  };

  const handleOpen2 = () => setmodalOpen2(true);

  const handleClose2 = (reason) => {
    if (reason && reason === "backdropClick") return;
    setmodalOpen2(false);
  };

  const handleOpen3 = () => setmodalOpen3(true);

  const handleClose3 = (reason) => {
    if (reason && reason === "backdropClick") return;
    setmodalOpen3(false);
  };

  const validationSchema = yup.object({
    locationname: yup.string().required("Robot Name is required"),
    metricpositionX: yup.string().required("RobotID is Required"),
    metricpositionY: yup.string().required("Location is required"),
    metricpositionYaw: yup
      .number("Latitude must be number or float")
      .required("Latitude is required"),
    status: yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      locationname: "",
      metricpositionX: "",
      metricpositionY: "",
      metricpositionYaw: "",
      status: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("values", values);
    },
  });

  const validationSchematwo = yup.object({
    locationname: yup.string().required("Robot Name is required"),
    metricpositionX: yup.string().required("RobotID is Required"),
    metricpositionY: yup.string().required("Location is required"),
    metricpositionYaw: yup
      .number("Latitude must be number or float")
      .required("Latitude is required"),
    status: yup.string(),
  });

  const formiktwo = useFormik({
    initialValues: {
      locationname: "",
      metricpositionX: "",
      metricpositionY: "",
      metricpositionYaw: "",
      status: "",
    },
    validationSchema: validationSchematwo,
    onSubmit: (values) => {
      console.log("values", values);
    },
  });

  const validationSchemathree = yup.object({
    locationname: yup.string().required("Robot Name is required"),
    metricpositionX: yup.string().required("RobotID is Required"),
    metricpositionY: yup.string().required("Location is required"),
    metricpositionYaw: yup
      .number("Latitude must be number or float")
      .required("Latitude is required"),
    status: yup.string(),
  });

  const formikthree = useFormik({
    initialValues: {
      locationname: "",
      metricpositionX: "",
      metricpositionY: "",
      metricpositionYaw: "",
      status: "",
    },
    validationSchema: validationSchemathree,
    onSubmit: (values) => {
      console.log("values", values);
    },
  });

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 36,
    height: 16,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#000",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark"
              ? "rgb(189,196,224)"
              : "rgb(189,196,224)",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 12,
      height: 12,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor:
        theme.palette.mode === "light" ? "#000" : "rgb(189,196,224)",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
      color: "rgb(189,196,224)",
      border: "2px solid rgb(189,196,224)",
    },
  }));

  useEffect(() => {
    homelocation.forEach((item, key) => {
      item.sno = key + 1;
    });
  });

  useEffect(() => {
    getmaplist(token)
      .then((res) => {
        setmaplist(res.data.data);
      })
      .catch((err) => {
        console.log("Error in Map list response", err);
      });
    getMapLocationList(fleetId, token)
      .then((res) => {
        console.log("res location lisr", res);
        setmaplocationlist(res.data.data);
      })
      .catch((err) => {
        console.log("Error in Map Location list response", err);
      });
  }, [refresh]);

  return (
    <>
      <div className="Fleetpage_map_tab_wrapper">
        <div className="Fleet_page_Mappage_Maptype_table_wrapper">
          <div className="Fleet_page_Map_list_wrapper">
            {maplist?.map((item, key) => {
              return (
                <div className="Fleet_page_Map_list_individual_map_div">
                  <div className="Fleet_page_Map_list_div_one">
                    <div>
                      {" "}
                      <img
                        className="Fleet_page_Map_list_img"
                        src={item.imageUrl}
                        alt="fleet_image"
                      />{" "}
                    </div>
                    <div>
                      <p className="Fleet_page_Map_list_p">
                        {" "}
                        Map ID : <span> {item.mapId}</span>
                      </p>
                      <p className="Fleet_page_Map_list_p">
                        {" "}
                        Map Name : <span>{item.mapName} </span>
                      </p>
                    </div>
                  </div>
                  <div>
                    <EditMap
                      mapData={item}
                      mapId={item.mapId}
                      changerefresh={changerefresh}
                    />
                    <DeleteMap item={item} changerefresh={changerefresh} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="Individual_fleet_page_locations_wrapper row pick-drop-css">
          <div className="col-md-6">
            <div className="Individual_fleet_page_pickup_location_wrapper">
              <div className="Individual_fleet_page_location_header_wrapper">
                <h4> PickUp Locations</h4>{" "}
                <button onClick={handleOpen1}> Add Pickup</button>
                <Modal
                  open={modalopen1}
                  onHide={handleClose1}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  backdrop="static"
                  keyboard={false}
                >
                  <Box sx={modalstyle1}>
                    <div className="Individual_fleetPage_pickupLocation_modal_wrapper">
                      <h3 className="Individual_fleetPage_pickupLocation_modal_h3">
                        {" "}
                        Add Pickup Location
                      </h3>
                      <div>
                        <form onSubmit={formik.handleSubmit}>
                          <div className="AddFleet_form_field_wrapper addlocation_textfield">
                            <TextField
                              id="standard-size-normal"
                              fullWidth
                              variant="standard"
                              value={formik.values.locationname}
                              name="locationname"
                              onChange={formik.handleChange}
                              placeholder="Location Name"
                              error={
                                formik.touched.locationname &&
                                Boolean(formik.errors.locationname)
                              }
                              helperText={
                                formik.touched.locationname &&
                                formik.errors.locationname
                              }
                              style={{ margin: "15px" }}
                            />
                            <TextField
                              id="standard-size-normal"
                              fullWidth
                              variant="standard"
                              value={formik.values.metricpositionX}
                              name="metricpositionX"
                              onChange={formik.handleChange}
                              placeholder="Metric Position X"
                              error={
                                formik.touched.metricpositionX &&
                                Boolean(formik.errors.metricpositionX)
                              }
                              helperText={
                                formik.touched.metricpositionX &&
                                formik.errors.metricpositionX
                              }
                              style={{ margin: "15px" }}
                            />
                          </div>
                          <div className="AddFleet_form_field_wrapper addlocation_textfield">
                            <TextField
                              id="standard-size-normal"
                              fullWidth
                              variant="standard"
                              value={formik.values.metricpositionY}
                              name="metricpositionY"
                              onChange={formik.handleChange}
                              placeholder="Metric PositionY"
                              error={
                                formik.touched.metricpositionY &&
                                Boolean(formik.errors.metricpositionY)
                              }
                              helperText={
                                formik.touched.metricpositionY &&
                                formik.errors.metricpositionY
                              }
                              style={{ margin: "15px" }}
                            />
                            <TextField
                              id="standard-size-normal"
                              fullWidth
                              variant="standard"
                              value={formik.values.metricpositionYaw}
                              name="metricpositionYaw"
                              onChange={formik.handleChange}
                              placeholder="Metric PositionYaw"
                              error={
                                formik.touched.metricpositionYaw &&
                                Boolean(formik.errors.metricpositionYaw)
                              }
                              helperText={
                                formik.touched.metricpositionYaw &&
                                formik.errors.metricpositionYaw
                              }
                              style={{ margin: "15px" }}
                            />
                          </div>
                          <div className="addlocation_toggle">
                            <p> Status </p>
                            <FormControlLabel
                              control={
                                <IOSSwitch
                                  checked={true}
                                  onChange={handlemaptoggle}
                                  name="googlemap"
                                />
                              }
                              label=""
                              sx={{ marginLeft: "0px", marginRight: "0px" }}
                            />
                          </div>
                          <div className="AddLocation_modal_button_wrapper">
                            <button
                              onClick={handleClose1}
                              className="AddLocation_modal_cancel_button"
                            >
                              {" "}
                              Cancel{" "}
                            </button>
                            <button
                              type="submit"
                              className="AddLocation_modal_save_button"
                            >
                              {" "}
                              Save{" "}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </Box>
                </Modal>
              </div>
              {maplocationlist?.map(
                (item) => item.locationType === "PICKUP"
              )[0] ? (
                <div className="Individual_fleet_page_location_content_wrapper">
                  <table className="Individual_fleet_page_location_content_table">
                    {" "}
                    <thead>
                      <tr>
                        {/* <th> S.NO</th> */}
                        <th> Location Name </th>
                        <th> Position (Lat, Long)</th>
                        <th> Status</th>
                        <th> </th>
                        <th> </th>
                      </tr>
                    </thead>
                    <tbody>
                      {maplocationlist?.map((item, key) => {
                        if (item.locationType === "PICKUP") {
                          return (
                            <tr>
                              {/* <td> {key+1} </td> */}
                              <td> {item.locationName}</td>
                              <td>
                                {" "}
                                {item.positionX}, {item.positionY}
                              </td>
                              <td className="Individual_FleetPage_Statustd_wrapper">
                                <FormControlLabel
                                  control={
                                    <IOSSwitch
                                      checked={item.status}
                                      onChange={handlemaptoggle}
                                      name="googlemap"
                                    />
                                  }
                                  label=""
                                />
                              </td>
                              <td>
                                <BorderColorIcon />{" "}
                              </td>
                              <td>
                                <DeleteIcon />{" "}
                              </td>
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </table>
                </div>
              ) : null}
            </div>
          </div>
          <div className="col-md-6">
            <div className="Individual_fleet_page_dropoff_location_wrapper">
              <div className="Individual_fleet_page_location_header_wrapper">
                <h4> Drop Off Locations</h4>
                <button onClick={handleOpen2}> Add DropOff</button>
                <Modal
                  open={modalopen2}
                  onHide={handleClose2}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  backdrop="static"
                  keyboard={false}
                >
                  <Box sx={modalstyle2}>
                    <div className="Individual_fleetPage_pickupLocation_modal_wrapper">
                      <h3 className="Individual_fleetPage_pickupLocation_modal_h3">
                        {" "}
                        Add DropOff Location
                      </h3>
                      <div>
                        <form onSubmit={formiktwo.handleSubmit}>
                          <div className="AddFleet_form_field_wrapper addlocation_textfield">
                            <TextField
                              id="standard-size-normal"
                              fullWidth
                              variant="standard"
                              value={formiktwo.values.locationname}
                              name="locationname"
                              onChange={formiktwo.handleChange}
                              placeholder="Location Name"
                              error={
                                formiktwo.touched.locationname &&
                                Boolean(formiktwo.errors.locationname)
                              }
                              helperText={
                                formiktwo.touched.locationname &&
                                formiktwo.errors.locationname
                              }
                              style={{ margin: "15px" }}
                            />
                            <TextField
                              id="standard-size-normal"
                              fullWidth
                              variant="standard"
                              value={formiktwo.values.metricpositionX}
                              name="metricpositionX"
                              onChange={formiktwo.handleChange}
                              placeholder="Metric Position X"
                              error={
                                formiktwo.touched.metricpositionX &&
                                Boolean(formiktwo.errors.metricpositionX)
                              }
                              helperText={
                                formiktwo.touched.metricpositionX &&
                                formiktwo.errors.metricpositionX
                              }
                              style={{ margin: "15px" }}
                            />
                          </div>
                          <div className="AddFleet_form_field_wrapper addlocation_textfield">
                            <TextField
                              id="standard-size-normal"
                              fullWidth
                              variant="standard"
                              value={formiktwo.values.metricpositionY}
                              name="metricpositionY"
                              onChange={formiktwo.handleChange}
                              placeholder="Metric PositionY"
                              error={
                                formiktwo.touched.metricpositionY &&
                                Boolean(formiktwo.errors.metricpositionY)
                              }
                              helperText={
                                formiktwo.touched.metricpositionY &&
                                formiktwo.errors.metricpositionY
                              }
                              style={{ margin: "15px" }}
                            />
                            <TextField
                              id="standard-size-normal"
                              fullWidth
                              variant="standard"
                              value={formiktwo.values.metricpositionYaw}
                              name="metricpositionYaw"
                              onChange={formiktwo.handleChange}
                              placeholder="Metric PositionYaw"
                              error={
                                formiktwo.touched.metricpositionYaw &&
                                Boolean(formiktwo.errors.metricpositionYaw)
                              }
                              helperText={
                                formiktwo.touched.metricpositionYaw &&
                                formiktwo.errors.metricpositionYaw
                              }
                              style={{ margin: "15px" }}
                            />
                          </div>
                          <div className="addlocation_toggle">
                            <p> Status </p>
                            <FormControlLabel
                              control={
                                <IOSSwitch
                                  checked={true}
                                  onChange={handlemaptoggle}
                                  name="googlemap"
                                />
                              }
                              label=""
                              sx={{ marginLeft: "0px", marginRight: "0px" }}
                            />
                          </div>
                          <div className="AddLocation_modal_button_wrapper">
                            <button
                              onClick={handleClose2}
                              className="AddLocation_modal_cancel_button"
                            >
                              {" "}
                              Cancel{" "}
                            </button>
                            <button
                              type="submit"
                              className="AddLocation_modal_save_button"
                            >
                              {" "}
                              Save{" "}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </Box>
                </Modal>
              </div>

              {maplocationlist?.map(
                (item) => item.locationType === "DROPOFF"
              )[0] ? (
                <div className="Individual_fleet_page_location_content_wrapper">
                  <table className="Individual_fleet_page_location_content_table">
                    <thead>
                      {console.log(
                        maplocationlist?.map(
                          (item) => item.locationType === "DROPOFF"
                        ),
                        "dfbdndgngngn"
                      )}
                      <tr>
                        {/* <th> S.NO</th> */}
                        <th> Location Name </th>
                        <th> Position (Lat, Long)</th>
                        <th> Status</th>
                        <th> Wander</th>
                        <th> </th>
                        <th> </th>
                      </tr>
                    </thead>
                    <tbody>
                      {maplocationlist?.map((item, key) => {
                        if (item.locationType === "DROPOFF")
                          return (
                            <tr>
                              {/* <td> {key+1} </td> */}
                              <td> {item.locationName}</td>
                              <td>
                                {" "}
                                {item.positionX} ,{item.positionY}
                              </td>
                              <td className="Individual_FleetPage_Statustd_wrapper">
                                <FormControlLabel
                                  control={
                                    <IOSSwitch
                                      checked={item.status}
                                      onChange={handlemaptoggle}
                                      name="googlemap"
                                    />
                                  }
                                  label=""
                                />
                              </td>
                              <td className="Individual_FleetPage_Statustd_wrapper">
                                <FormControlLabel
                                  control={
                                    <IOSSwitch
                                      checked={item.status}
                                      onChange={handlemaptoggle}
                                      name="googlemap"
                                    />
                                  }
                                  label=""
                                />{" "}
                              </td>
                              <td>
                                <BorderColorIcon />{" "}
                              </td>
                              <td>
                                <DeleteIcon />{" "}
                              </td>
                            </tr>
                          );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <p>&nbsp;</p>
        <div className="row">
          <div className="col-md-12">
            <div className="Individual_fleet_page_dropoff_location_wrapper">
              <div className="Individual_fleet_page_location_header_wrapper">
                <h4> Home Location</h4>
                <button onClick={handleOpen3}> Add Home Location</button>
                <Modal
                  open={modalopen3}
                  onHide={handleClose3}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  backdrop="static"
                  keyboard={false}
                >
                  <Box sx={modalstyle2}>
                    <div className="Individual_fleetPage_pickupLocation_modal_wrapper">
                      <h3 className="Individual_fleetPage_pickupLocation_modal_h3">
                        {" "}
                        Add Home Location
                      </h3>
                      <div>
                        <form onSubmit={formikthree.handleSubmit}>
                          <div className="AddFleet_form_field_wrapper addlocation_textfield">
                            <TextField
                              id="standard-size-normal"
                              fullWidth
                              variant="standard"
                              value={formikthree.values.locationname}
                              name="locationname"
                              onChange={formikthree.handleChange}
                              placeholder="Location Name"
                              error={
                                formikthree.touched.locationname &&
                                Boolean(formikthree.errors.locationname)
                              }
                              helperText={
                                formikthree.touched.locationname &&
                                formikthree.errors.locationname
                              }
                              style={{ margin: "15px" }}
                            />
                            <TextField
                              id="standard-size-normal"
                              fullWidth
                              variant="standard"
                              value={formikthree.values.metricpositionX}
                              name="metricpositionX"
                              onChange={formikthree.handleChange}
                              placeholder="Metric Position X"
                              error={
                                formikthree.touched.metricpositionX &&
                                Boolean(formikthree.errors.metricpositionX)
                              }
                              helperText={
                                formikthree.touched.metricpositionX &&
                                formikthree.errors.metricpositionX
                              }
                              style={{ margin: "15px" }}
                            />
                          </div>
                          <div className="AddFleet_form_field_wrapper addlocation_textfield">
                            <TextField
                              id="standard-size-normal"
                              fullWidth
                              variant="standard"
                              value={formikthree.values.metricpositionY}
                              name="metricpositionY"
                              onChange={formikthree.handleChange}
                              placeholder="Metric PositionY"
                              error={
                                formikthree.touched.metricpositionY &&
                                Boolean(formikthree.errors.metricpositionY)
                              }
                              helperText={
                                formikthree.touched.metricpositionY &&
                                formikthree.errors.metricpositionY
                              }
                              style={{ margin: "15px" }}
                            />
                            <TextField
                              id="standard-size-normal"
                              fullWidth
                              variant="standard"
                              value={formikthree.values.metricpositionYaw}
                              name="metricpositionYaw"
                              onChange={formikthree.handleChange}
                              placeholder="Metric PositionYaw"
                              error={
                                formikthree.touched.metricpositionYaw &&
                                Boolean(formikthree.errors.metricpositionYaw)
                              }
                              helperText={
                                formikthree.touched.metricpositionYaw &&
                                formikthree.errors.metricpositionYaw
                              }
                              style={{ margin: "15px" }}
                            />
                          </div>
                          <div className="addlocation_toggle">
                            <p> Status </p>
                            <FormControlLabel
                              control={
                                <IOSSwitch
                                  checked={true}
                                  onChange={handlemaptoggle}
                                  name="googlemap"
                                />
                              }
                              label=""
                              sx={{ marginLeft: "0px", marginRight: "0px" }}
                            />
                          </div>
                          <div className="AddLocation_modal_button_wrapper">
                            <button
                              onClick={handleClose3}
                              className="AddLocation_modal_cancel_button"
                            >
                              {" "}
                              Cancel{" "}
                            </button>
                            <button
                              type="submit"
                              className="AddLocation_modal_save_button"
                            >
                              {" "}
                              Save{" "}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </Box>
                </Modal>
              </div>
              {maplocationlist?.map(
                (item) => item.locationType === "HOME"
              )[0] ? (
                <table className="Individual_fleet_page_location_content_table">
                  <thead>
                    <tr>
                      {/* <th> S.No</th> */}
                      <th> Location Name</th>
                      <th> Position (Lat, Long)</th>
                      <th> Status </th>
                      <th> Assign Robot</th>
                      <th> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {maplocationlist?.map((item, key) => {
                      if (item.locationType === "HOME") {
                        return (
                          <tr>
                            {/* <td> {key+1}</td> */}
                            <td> {item.locationName}</td>
                            <td>
                              {" "}
                              {item.positionX} , {item.positionY}
                            </td>
                            <td className="Individual_FleetPage_Statustd_wrapper">
                              <FormControlLabel
                                control={
                                  <IOSSwitch
                                    checked={item.status}
                                    onChange={handlemaptoggle}
                                    name="googlemap"
                                  />
                                }
                                label=""
                              />
                            </td>
                            <td
                              style={{ display: "flex", width: "400px" }}
                              className="INdividual_fleetPage_assignrobot_select"
                            >
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Age
                                </InputLabel>
                                <Select
                                  sx={{ width: "80%" }}
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={robotassign}
                                  label="Age"
                                  onChange=""
                                >
                                  <MenuItem value={10}>Ten</MenuItem>
                                  <MenuItem value={20}>Twenty</MenuItem>
                                  <MenuItem value={30}>Thirty</MenuItem>
                                </Select>
                              </FormControl>
                              <button className="AssignRobot_button">
                                {" "}
                                REMOVE ROBOT
                              </button>
                            </td>
                            <td>
                              <CreateOutlinedIcon />
                              <DeleteIcon sx={{ color: "#E96C67" }} />
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </table>
              ) : null}
            </div>
          </div>
        </div>
        <ToastContainer />
        {maplist?.length > 0 ? null : <AddMap changerefresh={changerefresh} />}
      </div>
    </>
  );
}

export default FleetPageMapPage;
