import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  getmaplist,
  getMapLocationList,
  getrobotStatus,
  MapDetails,
  robotListfleetId,
} from "../API/index";
// import {
//   Circle,
//   ImageOverlay,
//   LayerGroup,
//   MapContainer,
//   Marker,
//   Popup,
//   SVGOverlay,
//   TileLayer,
//   useMap,
// } from "react-leaflet";
// import { CRS } from "leaflet";
// import icon from "leaflet/dist/images/marker-icon.png";
// import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { useSelector } from "react-redux";
import * as d3 from "d3";
import { RobotIdAction } from "../redux/Actions";
import { useDispatch } from "react-redux";
import $ from "jquery";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import io from "socket.io-client";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Scatter } from "react-chartjs-2";
import { fleetListAndRobotStatusByUserEmail } from "../redux/actions/fleet";

const L = window["L"];

function Maps(props) {
  let socket;
  // const serverUrl = 'http://localhost:3000';
  const serverUrl = "https://hammerhead.ottonomy.io";
  const bounds = [
    [-3000, -3000],
    [3000, 3000],
  ];
  const token = localStorage.getItem("token");
  // let DefaultIcon = L.icon({
  //   iconUrl: icon,
  //   shadowUrl: iconShadow,
  //   iconSize: [24, 36],
  //   iconAnchor: [13, 36],
  // });
  // const iconPerson = new L.Icon({
  //   iconAnchor: null,
  //   popupAnchor: null,
  //   shadowUrl: null,
  //   shadowSize: null,
  //   shadowAnchor: null,
  //   iconSize: new L.Point(60, 75),
  //   className: "leaflet-div-icon",
  // });

  const [mapUrl, setmapurl] = useState();
  const [XCoordinate, setXCoordinate] = useState(0);
  const [YCoordinate, setYCoordinate] = useState(0);
  const [baseUrl, setbaseUrl] = useState();
  const [locationList, getLocationList] = useState();
  const [mapWidth, setmapwidth] = useState(0);
  const [mapHeight, setmapHeight] = useState(0);
  const [coordinates, setCoordinates] = useState([{ x: 0, y: 0 }]);
  const [imagestate, setimagestate] = useState({
    backgroundImage: `url(${baseUrl})`,
    backgroundPosition: "0% 0%",
  });
  // const [pointImage, setPointImage] = useState(
  //   "/assets/images/robot-icon-dropoff_1.svg"
  // );

  const fleetId = localStorage.getItem("fleetId");
  const robotsid = useSelector((state) => state.robotidReducer);
  let robotlist = [];
  let mapWi = "";
  let mapHe = "";
  const role = localStorage.getItem("role");

  const dispatch = useDispatch();

  const emailId = localStorage.getItem("useremail");

  const updateState = useCallback(async () => {
    dispatch(fleetListAndRobotStatusByUserEmail(emailId, role));
  }, [dispatch, emailId]);

  const useIntervalAsync = (callbackFunction, intervalInMs) => {
    const timeout = useRef();

    const apiCall = useCallback(async () => {
      await callbackFunction();
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
      timeout.current = window.setTimeout(apiCall, intervalInMs);
    }, [callbackFunction, intervalInMs]);

    useEffect(() => {
      apiCall();
      return () => {
        clearTimeout(timeout.current);
      };
    }, [apiCall]);
  };

  useIntervalAsync(updateState, 5000);

  const { fleetList } = useSelector((state) => state.fleetList);

  useEffect(() => {
    createmap(locationList, fleetList[0]?.robots);
    dispatch(RobotIdAction(fleetList));
    // return ()=>{
    //   clearInterval(robotstatusfleetId)
    //  }
  }, [locationList]);

  const createmap = (locationList, robotlist) => {
    // console.log('locationListlocationList',locationList,robotlist)
    // console.log("createmap called inside", robotlist);
    const xSize = mapWidth;
    const ySize = mapHeight;
    const margin = 20;
    const xMax = xSize - margin * 2;
    const yMax = ySize - margin * 2;

    let robotDataResult = robotlist?.map((robotData) => {
      // if (robotData.robotId === "OB02221011")
      return {
        // x: 200,
        // y: 200,
        y: robotData.positionX / 10,
        x: robotData.positionY / 10,
        robotID: robotData.robotId,
        lat: robotData.latitude,
        long: robotData.longitude,
        // x: 100,
        // y: 100,
      };
    });

    // console.log("robotDataResult", robotDataResult);
    setCoordinates(robotDataResult);
    // console.log("coordinates__", coordinates);
    // if (props.robotlistData.robots.locationType === "PICKUP") {
    //   return "/assets/images/robot-icon-pickup_1.svg";
    // } else if (props.robotlistData.robots.locationType === "HOME") {
    //   return "/assets/images/robot-icon-pickup_1.svg";
    // } else if (props.robotlistData.robots.locationType === "ROBOT") {
    //   return "/assets/images/robot-icon-dropoff_1.svg";
    // } else {
    //   return "/assets/images/robot-icon-dropoff_1.svg";
    // }

    // const imagePath = "/assets/67ryo.png";
    // const robotImagePath =
    //   process.env.PUBLIC_URL + "/assets/images/robot-icon-pickup_1.svg";

    // "/assets/images/robot-icon-pickup_1.svg
    // "/assets/images/robot-icon-pickup_1.svg
    // "/assets/images/robot-icon-dropoff_1.svg
    // "/assets/images/robot-icon-dropoff_1.svg
  };

  useEffect(() => {
    let mapdata;
    getmaplist(token)
      .then((res) => {
        mapdata = res;
        setXCoordinate(mapdata.data.data[0]?.mapOrigionX);
        setYCoordinate(mapdata.data.data[0]?.mapOrigionY);
        setbaseUrl(mapdata.data.data[0]?.imageUrl);
        localStorage.setItem("mapid", mapdata.data.data[0]?.mapId);
        //  console.log('mapdata',mapdata,XCoordinate,baseUrl)
        let mapW = mapdata.data.data[0]?.mapWidth;
        setmapwidth(mapW);
        let mapH = mapdata.data.data[0]?.mapHeight;
        setmapHeight(mapH);
        setmapurl({ ...mapUrl, mapdata });
      })
      .catch((err) => {
        // console.log("Maplist Error", err);
      });

    let mapID = localStorage.getItem("mapid");
    getMapLocationList(props.fleetId, token)
      .then((res) => {
        // console.log("Map Location List", res);
        getLocationList(res.data.data);
      })
      .catch((err) => {
        // console.log("Error Location List", err);
      });
    mapWi = parseInt(mapWidth);
    mapHe = parseInt(mapHeight);
  }, []);
  const position = [XCoordinate, YCoordinate];
  const mapRef = useRef();
  const [tankR, setTankR] = useState(0);
  const center = [51.505, -0.09];

  ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

  // Customize chart options
  const options = {
    scales: {
      x: {
        min: 0,
        display: false, // Hide y-axis
        max: mapWidth / 10,
        reverse: true,
        position: "right",
      },
      y: {
        min: 0,
        display: false, // Hide x-axis
        max: mapHeight / 10,
        position: "top",
        reverse: true,
      },
    },
    plugins: {
      legend: {
        display: false, // Hide legend
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `Robot ID: ${context.raw.robotID}
            X: ${context.raw.x}
            Y: ${context.raw.y}
            Latitude: ${context.raw.lat}
            Longitude: ${context.raw.long}`;
          },
        },
      },
    },
  };

  const pointImage = new Image();
  pointImage.src = "/assets/images/robot-icon-pickup_1.svg";
  pointImage.width = 35;
  pointImage.height = 35;

  // Convert coordinates to Chart.js dataset format
  const data = {
    datasets: [
      {
        data: coordinates,
        // data: [
        //   { x: 100, y: 100 },
        //   { x: 200, y: 200 },
        // ],
        pointStyle: pointImage, // Use image as point style
        pointRadius: 20, // Set the size of the points
        showLine: false, // Hide the lines between points
      },
    ],
  };

  return (
    <>
      <div className="Map-Main-Whole-wrapper border-blue-top">
        <figure className="frame">
          <TransformWrapper sx={{ width: "100%", height: "100%" }}>
            {({ zoomIn, zoomOut, resetTransform }) => (
              <>
                {mapWidth === undefined || mapWidth === null ? (
                  <div>
                    <p>No Data for 2D Map</p>
                  </div>
                ) : (
                  <div className="tools">
                    <button onClick={() => zoomIn()}>+</button>
                    <button onClick={() => zoomOut()}>-</button>
                    <button onClick={() => resetTransform()}>Reset</button>
                  </div>
                )}
                <TransformComponent sx={{ width: "100%", height: "100%" }}>
                  <div
                    id="myPlot"
                    className="zoom"
                    style={{
                      width: mapWidth / 10,
                      height: mapHeight / 10,
                      backgroundImage: `url(${baseUrl})`,
                      backgroundSize: "contain",
                      backgroundPosition: "0% 0%",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <Scatter
                      width={mapWidth / 10}
                      height={mapHeight / 10}
                      options={options}
                      data={data}
                    />
                  </div>
                </TransformComponent>
                {/* {console.log('baseUrl',baseUrl)} */}
              </>
            )}
          </TransformWrapper>
        </figure>
        {/* 
    ${baseUrl}
     width:mapWidth/10
     height:mapHeight/10
*/}
      </div>
    </>
  );
}

export default React.memo(Maps);
