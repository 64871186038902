import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { FormControlLabel, Switch } from "@mui/material";
import OrderList from "../subcomponents/OrderList";
import FleetPageMapPage from "./FleetPageMapPage";
import FleetPagerobotPage from "./FleetPagerobotPage";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useDispatch, useSelector } from "react-redux";
import { dispatch } from "d3";
import { fleetListAndRobotStatusByUserEmail } from "../redux/actions/fleet";
import VideoStream from "./VideoStream";
import Dropdown from "react-bootstrap/Dropdown";

function FleetPageTopBar(props) {
  const [topbar, settopbar] = useState("videostream");
  const [googlemap, setgooglemap] = useState(true);

  const { fleetList } = useSelector((state) => state.fleetList);
  const robotDetails = useSelector((state) => state.robotDetailsReducer);
  const fleetData = JSON.parse(localStorage.getItem("fleetData"));

  const emailId = localStorage.getItem("emailId");
  const role = localStorage.getItem("role");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handletoplink = (value) => {
    settopbar(value);
  };

  const handlemaptoggle = () => {
    setgooglemap(!googlemap);
  };

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 36,
    height: 16,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#000",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark"
              ? "rgb(189,196,224)"
              : "rgb(189,196,224)",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 12,
      height: 12,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor:
        theme.palette.mode === "light" ? "#000" : "rgb(189,196,224)",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
      color: "rgb(189,196,224)",
      border: "2px solid rgb(189,196,224)",
    },
  }));

  useEffect(() => {
    if (!fleetList) {
      dispatch(fleetListAndRobotStatusByUserEmail(emailId, role));
    }
  }, [fleetList, dispatch, emailId]);

  return (
    <>
      <div className="Dashboard_page_rightSide_content_heading">
        <div style={{ height: "100%" }}>
          <p className="FleetView_page_haeding_tag">
            {" "}
            Fleet <ArrowForwardIosIcon style={{ fontSize: "16px" }} />
            <span className="FleetView_page_Heading_fleetName">
              {" "}
              {fleetData?.fleetName}
            </span>
          </p>
        </div>
        <div>
          <div className="FleetPage_top_bar_left_side">
            {/* <div
              className={topbar === "robots" ? "top_bar_active" : ""}
              onClick={() => handletoplink("robots")}
            >
              {" "}
              Robots
            </div> */}

            {/* <div
              className={topbar === "orders" ? "top_bar_active" : ""}
              onClick={() => handletoplink("orders")}
            >
              {" "}
              Orders{" "}
            </div> */}

            <div
              className={topbar === "videostream" ? "top_bar_active" : ""}
              onClick={() => handletoplink("videostream")}
            >
              {" "}
              Collective Videos{" "}
            </div>
          </div>
          <Dropdown
            onSelect={(eventKey) => {
              handletoplink(eventKey);
            }}
            id="menu_dropdown_select"
          >
            <Dropdown.Toggle id="dropdown-basic">{"Robots"}</Dropdown.Toggle>
            <Dropdown.Menu className="custom-dropdown-menu">
              <Dropdown.Item key={"robots"} eventKey={"robots"}>
                Robots
              </Dropdown.Item>
              <Dropdown.Item key={"orders"} eventKey={"orders"}>
                Orders
              </Dropdown.Item>
              <Dropdown.Item key={"videostream"} eventKey={"videostream"}>
                Collective Video
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        {/* {topbar === "robots" ? (
          <div className="FleetPage_map_toggle_wrapper d-flex-center" >
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={googlemap}
                  onChange={handlemaptoggle}
                  name="googlemap"
                />
              }
              label=""
            />
            <p> Map</p>
          </div>
        ) : null} */}
      </div>
      {topbar === "robots" ? (
        <FleetPagerobotPage googlemap={googlemap} fleetData={fleetData} />
      ) : topbar === "maps" ? (
        <FleetPageMapPage fleetData={fleetData} />
      ) : topbar === "videostream" ? (
        <VideoStream
          fleetData={fleetData}
          robotData={robotDetails && Object.values(robotDetails)}
        />
      ) : (
        <OrderList fleetData={fleetData} />
      )}
    </>
  );
}

export default FleetPageTopBar;
