import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import DashboardSideBar from "./DashboardSideBar";

// import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
// import SaveIcon from "@mui/icons-material/Save";
// import PrintIcon from "@mui/icons-material/Print";
// import ShareIcon from "@mui/icons-material/Share";
import {  useNavigate } from "react-router-dom";
import { deleteuser, userlist } from "../API";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import DashboardBottomBar from "../components/DashboardBottomBar";

function UserList(props) {
  const navigate = useNavigate();
  const toastmsg = (msg) => toast(msg);
  // const emailId = localStorage.getItem("useremail");
  const [userListdata, setuserlistData] = useState();
  const token = localStorage.getItem("token");
  useEffect(() => {
    userlist(token)
      .then((res) => {
        setuserlistData(res.data.users);
        console.log("User list response", res);
      })
      .catch((err) => {
        console.log("User list error", err);
      });
  }, []);

  const isopen = useSelector((state) => state.dashboardopenReducer);

  const handleAddFleet = () => {
    navigate("/adduser");
  };
  const handleuserEdit = (item) => {
    navigate(`/edituser/${item._id}`, { state: { data: item } });
  };
  const handleuserdelete = (item) => {
    setuserlistData(userListdata.filter((itm) => itm._id !== item._id));
    deleteuser(item._id, token)
      .then((res) => {
        toastmsg(res.data.message);
      })
      .catch((err) => {
        toastmsg(err.data.message);
      });
  };
  const handleUserClick = (item) => {
    navigate(`/userlist/${item.emailId}`, { state: { data: item } });
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "#282F42",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = (reason) => {
    if (reason && reason == "backdropClick") return;
    setOpen(false);
  };
  return (
    <>
      <Header />
      <div className="Dashboard_page_Whole_content_wrapper_main">
              <DashboardSideBar
                dashboardopen={props.dashboardopen}
                active="userlist"
              />
                   <DashboardBottomBar active="userlist" />
            </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2">
            
          </div>
     
          <div className="col-md-10">
            <div style={{height:'100%'}} className={isopen.userlogindata ? "" : ""}>
              <div className="Dashboard_page_rightSide_content_heading">
                <h4 className="Dashboard_page_Robot_Card_heading">User List</h4>
              </div>
              <div className="over-scroll">
                <table
                  style={{ width: "100%" }}
                  className="Fleet_page_table_Main_whole"
                >
                  <thead>
                    <tr>
                      <th> Role </th>
                      <th> Name </th>
                      <th> Email Address</th>
                      <th> Pin</th>
                      <th> Contact Number</th>
                      <th> Action </th>
                    </tr>
                  </thead>
                  <tbody>
                    {userListdata?.map((item) => {
                      return (
                        <tr>
                          <td> {item.role}</td>
                          <td> {item.firstName ? item.firstName : "N/A"}</td>
                          <td> {item.username}</td>
                          <td> {item.pin}</td>
                          <td> {item.contactNumber} </td>
                          <td className="FleetPage_table_action_td">
                            <img
                              className="cursor_pointer"
                              src="/assets/images/arrow-link-icon.svg"
                              onClick={() => handleUserClick(item)}
                              alt=""
                            />
                            <img
                              className="cursor_pointer"
                              src="/assets/images/b.svg"
                              onClick={() => handleuserEdit(item)}
                              alt=""
                            />
                            <Button onClick={handleOpen}>
                              <img
                                className="cursor_pointer"
                                src="/assets/images/Deleteiconlist.svg"
                                alt=""
                              />
                            </Button>
                            <Modal
                              open={open}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                              backdrop="static"
                              keyboard={false}
                              style={{ opacity: "0.5" }}
                            >
                              <Box sx={style}>
                                <div className="Delete_modal_close_btn">
                                  <p className="Modal-wrapper-p">
                                    {" "}
                                    Are you sure to want to delete the User ?
                                  </p>
                                </div>
                                <div className="Modal_content_button_wrapper">
                                  <button
                                    onClick={() => {
                                      handleuserdelete(item);
                                      handleClose();
                                    }}
                                    className="Modal-btn Modal-btn-red yes-red-active"
                                  >
                                    {" "}
                                    Yes
                                  </button>
                                  <button
                                    onClick={handleClose}
                                    className="Modal-button-Main-btn"
                                  >
                                    {" "}
                                    No{" "}
                                  </button>
                                </div>
                              </Box>
                            </Modal>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button className="FleetPage_plusIcon" onClick={handleAddFleet}>
        <AddIcon />
      </button>
      <ToastContainer />
    </>
  );
}

export default UserList;
