import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../components/Header";
import DashboardSideBar from "./DashboardSideBar";
// import SpeedDial from "@mui/material/SpeedDial";
// import SpeedDialIcon from "@mui/material/SpeedDialIcon";
// import SpeedDialAction from "@mui/material/SpeedDialAction";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import { fleetlist } from "../API";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import {
//   Button,
//   FormControl,
//   InputLabel,
//   MenuItem,
//   Select,
// } from "@mui/material";
// import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import DashboardBottomBar from "../components/DashboardBottomBar";
import { storeRobotDetails } from "../redux/Actions";
import { fleetListAndRobotStatusByUserEmail } from "../redux/actions/fleet";

function RobotPage(props) {
  const [selectfleet, setselectfleet] = useState("");
  const [status, setstatus] = useState("");
  const emailId = localStorage.getItem("emailId");
  const role = localStorage.getItem("role");
  const [robotlistData, setrobotlistData] = useState();
  const [copyrobotlist, setcopyrobotlist] = useState();
  const navigate = useNavigate();
  const toastmsg = (msg) => toast(msg);
  const { sidebarcollapse } = useSelector((state) => state.login);
  const token = localStorage.getItem("token");

  const { fleetList } = useSelector((state) => state.fleetList);
  const robotDetails = useSelector((state) => state.robotDetailsReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      !localStorage.getItem("token") ||
      localStorage.getItem("token") === null ||
      localStorage.getItem("token") === "null" ||
      localStorage.getItem("token") === undefined
    ) {
      navigate("/");
    }
  }, []);

  // useEffect(() => {
  //   fleetlist(emailId, token)
  //     .then((res) => {
  //       setrobotlistData(res.data.fleet);
  //       console.log('res.data.fleet',res.data.fleet)
  //       res.data.fleet.map((item)=>{
  //         dispatch(storeRobotDetails(item.fleetId));
  //         return null
  //       })
  //       // dispatch(storeRobotDetails(fleedId));
  //       // console.log("Robot page response", res);
  //     })
  //     .catch((err) => {
  //       // console.log("Robot page error", err);
  //     });
  //   setcopyrobotlist(robotlistData);
  // }, []);

  const updateState = useCallback(async () => {
    dispatch(fleetListAndRobotStatusByUserEmail(emailId, role));
    if (fleetList.length > 0) {
      fleetList.map((fleet) => {
        dispatch(storeRobotDetails(fleet.fleetId));
        return null;
      });
    }
  }, [dispatch, emailId, fleetList]);

  const useIntervalAsync = (callbackFunction, intervalInMs) => {
    const timeout = useRef();

    const apiCall = useCallback(async () => {
      await callbackFunction();
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
      timeout.current = window.setTimeout(apiCall, intervalInMs);
    }, [callbackFunction, intervalInMs]);

    useEffect(() => {
      apiCall();
      return () => {
        clearTimeout(timeout.current);
      };
    }, [apiCall]);
  };

  useIntervalAsync(updateState, 5000);

  const isopen = useSelector((state) => state.dashboardopenReducer);

  const handlerobotClick = (itm) => {
    let latitude;
    let longitude;
    Object.values(robotDetails).map((ttt) => {
      if (ttt.fleetId === itm.fleetId) {
        ttt.robots.map((itmmm) => {
          if (itmmm.robotId === itm.robotId) {
            latitude = itmmm.latitude;
            longitude = itmmm.longitude;
          }
        });
      }
    });
    navigate(`/robotPage/${itm.robotId}`, {
      state: {
        data: itm,
        latitude: latitude,
        longitude: longitude,
        robotlistData: Object.values(robotDetails),
        marker: "example",
      },
    });
  };

  // const handleaddrobot = () => {
  //   naviate("/addrobot");
  // };

  // const handleRobotEdit = (itm) => {
  //   navigate(`/editrobot/${itm.robotId}`, { state: { data: robotlistData } });
  // };
  // const handleSelectfleet = (e) => {
  //   setselectfleet(e.target.value);
  // };
  // const handleStatusChange = (e) => {
  //   setstatus(e.target.value);
  // };
  const getrobotStatustwo = (status) => {
    switch (status) {
      case "UNAVAILABLE":
        return (
          <span className="color-red Dashboard_page_robotStatusClass">
            {" "}
            UNAVAILABLE
          </span>
        );
      case "AVAILABLE":
        return (
          <span className="color-green Dashboard_page_robotStatusClass">
            {" "}
            AVAILABLE
          </span>
        );
      case "ERROR":
        return (
          <span className="color-yellow Dashboard_page_robotStatusClass">
            {" "}
            Error
          </span>
        );
      default:
        return (
          <span className="color-red Dashboard_page_robotStatusClass">
            {" "}
            {status}
          </span>
        );
    }
  };
  return (
    <>
      <Header />
      <div className="Fleet_page_Whole_content_wrapper_main">
        {/* {console.log('robotlistData',robotlistData)} */}
      </div>
      <div className="container-fluid">
        <div className="row">
          <div
            className={sidebarcollapse ? "col-md-2 collapseWidth" : "col-md-2"}
          >
            <DashboardSideBar
              dashboardopen={props.dashboardopen}
              active="robotpage"
            />
            <DashboardBottomBar active="robotpage" />
          </div>

          <div
            className={`${
              sidebarcollapse ? "col-md-10 collapseWidth" : "col-md-10"
            }`}
          >
            <div
              style={{ height: "100%" }}
              className={isopen.userlogindata ? "" : ""}
            >
              <div className="Dashboard_page_rightSide_content_heading">
                <h4>Robots</h4>
              </div>
              <div className="">
                <table
                  style={{ width: "100%" }}
                  className="Fleet_page_table_Main_whole"
                >
                  <thead>
                    <tr>
                      <th> Robot Id</th>
                      <th> Fleet Name</th>
                      {/* <th> Robot Name </th> */}
                      {/* <th> End Point</th>
                      <th> Date Created</th>
                      <th> SSh End Point</th> */}
                      <th> Status</th>
                      {/* <th> </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {robotDetails &&
                      Object.values(robotDetails)?.map((item, key) => {
                        return (
                          <tr key={key}>
                            <td className="Robot_page_robotId_td">
                              {" "}
                              {item?.robotId}{" "}
                              {/* <sup
                                className={
                                  item.robots[key].connectionStatus ===
                                    "OFFLINE"
                                    ? "p-color-red-two"
                                    : "p-color-green-two"
                                }
                              >
                                {" "}
                                {item.robots[key].connectionStatus}{" "}
                              </sup>
                              {item.robots[key].connectionStatus ===
                                "OFFLINE" ? (
                                <span> {item.robots[key].lastUpdatedTime}</span>
                              ) : null} */}
                            </td>
                            <td>
                              {" "}
                              {fleetList &&
                                fleetList.find(
                                  (fleet) => fleet?.fleetId === item?.fleetId
                                ) &&
                                fleetList.find(
                                  (fleet) => fleet?.fleetId === item?.fleetId
                                )?.fleetName}
                            </td>
                            {/* <td> {item.robotId}</td> */}
                            {/* <td> {item.endpoint}</td>
                            <td> {item.createdAt}</td>
                            <td> {item.sshEndpoint} </td> */}

                            <td className="FleetPage_table_status_td">
                              {getrobotStatustwo(item?.robotStatus)}

                              {/* {item?.robotStatus === "UNAVAILABLE" ? (
                                <span> {item.updatedAt}</span>
                              ) : null} */}
                              {/* <p
                                className={
                                  item?.connectionStatus === "OFFLINE"
                                    ? "p-color-red-two"
                                    : "p-color-green-two"
                                }
                              >
                                {" "}
                                {item?.connectionStatus}{" "}
                              </p>
                              {item?.connectionStatus === "OFFLINE" ? (
                                <span> {item.updatedAt}</span>
                              ) : null} */}
                            </td>
                            <td className="FleetPage_table_action_td">
                              <img
                                className="cursor_pointer"
                                onClick={() => handlerobotClick(item)}
                                src="/assets/images/arrow-link-icon.svg"
                                alt=""
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <button className="FleetPage_plusIcon" onClick={handleaddrobot}>
        <AddIcon />
      </button> */}
    </>
  );
}

export default RobotPage;
