import React from "react";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import { useSelector } from "react-redux";
import { restartvideo } from "../API";
import { toast, ToastContainer } from "react-toastify";

function VideoStream(props) {
  const { fleetList } = useSelector((state) => state.fleetList);
  const toastmsg = (msg) => toast(msg);
  const token = localStorage.getItem("token");
  const handleVideorestart = (item) => {
    // console.log(item.robotId);
    restartvideo(item.robotId, token)
      .then((res) => {
        console.log("Restart Video Response", res);
        toastmsg(res.data.message);
      })
      .catch((err) => {
        console.log("Restart Video Error", err);
        toastmsg(err.data.message);
      });
  };
  return (
    <>
      {/* <Header fleetName={true} fleetData={fleetData}/> */}

      <div className="Dashboard_page_content">
        <div className="Video_page_wrapper">
          {/* {console.log('fleetList',fleetList)}
           */}
          {props?.robotData
            ?.filter((robot) => robot?.fleetId === props?.fleetData?.fleetId)
            ?.map((item, index) => {
              return (
                <div key={index} className="Video_page_wrapper_div">
                  <div className="d-flex-center">
                    <p className="Video_page_wrapper_p"> {item?.robotId}</p>
                    <button
                      className="restart_video_button"
                      onClick={() => handleVideorestart(item)}
                    >
                      {" "}
                      Restart Video{" "}
                    </button>
                  </div>

                  {/* {console.log('item?.viewerurl',item?.viewerurl)} */}
                  <iframe
                    className="Video_stream_iframe_two"
                    title="collective_stream"
                    autoControls={true}
                    src={`https://viewer.millicast.com?streamId=rGE9Jy/${item?.robotId}`}
                    allowFullScreen
                  ></iframe>
                  {/* {item?.fleetId === props.robotData.flee ? 
             : 
             <div className='No_video_available_div'> 
               <p> No video Available </p>
            </div>} */}
                </div>
              );
            })}
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default VideoStream;
