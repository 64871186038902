import { robotConstants } from "./Contants";
import axios from "axios";

export const dashboardopenaction = (data) => {
  return {
    type: "OPEN",
    payload: data,
  };
};
export const dashboardcloseaction = (data) => {
  return {
    type: "CLOSE",
    payload: data,
  };
};

// ===================New==========================================================================================================
export const userlogin = (data) => {
  return {
    type: "USER_LOGIN",
    payload: data,
  };
};
export const FleetIdAction = (data) => {
  return {
    type: "FETCH_FLEET_ID",
    payload: data,
  };
};
export const robotDataAction = (data) => {
  return {
    type: "FETCH_ROBOT_DATA",
    payload: data,
  };
};
export const robotmsgAction = (data) => {
  return {
    type: "FETCH_ROBOTMSG_DATA",
    payload: data,
  };
};
export const RobotIdAction = (data) => {
  return {
    type: "GET_ROBOT_ID",
    payload: data,
  };
};
export const robotlistdataAction = (data) => {
  return {
    type: "FETCH_ROBOT_LIST_DATA",
    payload: data,
  };
};
export const sshendpointaction = (data) => {
  return {
    type: "FETCH_SSH_ENDPOINT",
    payload: data,
  };
};
export const fleetlatitudeaction = (data) => {
  return {
    type: "FETCH__FLEET_LATITUDE",
    payload: data,
  };
};
export const fleetlogitudeaction = (data) => {
  return {
    type: "FETCH__FLEET_LONGITUDE",
    payload: data,
  };
};

export const websocketws = () => {
  return {
    type: "FETCH_WEBSOCKET_CONNECTION",
  };
};
export const robotStatusAction = (data) => {
  return {
    type: "FETCH_ROBOT_STATUS_DATA",
    payload: data,
  };
};
export const robotCommandListAction = (data) => {
  return {
    type: "FETCH_ROBOT_COMMANDLIST_DATA",
    payload: data,
  };
};
export const robotStatusFleetAction = (data) => {
  console.log("Data Dta dtdt", data);
  return {
    type: "FETCH_ROBOT_STATUSFLEET_DATA",
    payload: data,
  };
};
export const FleetandRobotlistbyemailAction = (data) => {
  return {
    type: "FETCH_FLEET_ROBOT_EMAIL",
    payload: data,
  };
};

export const storeRobotDetails = (fleetId) => async (dispatch) => {
  try {
    dispatch({
      type: robotConstants.ROBOT_DETAILS_REQUEST,
    });

    const token = localStorage.getItem("token");

    let { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}robot/v1/getRobotStatusByFleetId`,
      {
        fleetId: fleetId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    let robotDetail = {};

    data.data.forEach((robot) => {
      robotDetail[robot[0].robotId] = robot[0];
    });
    if (Object.keys(robotDetail).length !== 0) {
      dispatch({
        type: robotConstants.ROBOT_DETAILS_SUCCESS,
        payload: robotDetail,
      });
    } else {
      throw new Error("No robot found!");
    }
  } catch (error) {}
};

// GET all robots details
export const getRobotDetail = (robotId) => async (dispatch) => {
  try {
    dispatch({
      type: robotConstants.ROBOT_DETAILS_REQUEST,
    });

    const token = localStorage.getItem("token");

    const { data: robotStatusData } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}robot/v1/getRobotStatus`,
      {
        robotId: robotId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const { data: robotCommandData } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}robot/v1/getRobotCommandListByRobotId`,
      {
        robotId: robotId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    const { data: robotPathData } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}robot/v2/getRobotPath`,
      {
        robotId: robotId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    let robotDetail = {};

    robotDetail[robotId] = robotStatusData.data.robotStatus;
    robotDetail[robotId].robotCommandList = robotCommandData.robotCommandList;
    robotDetail[robotId].path = robotPathData.path;

    dispatch({
      type: robotConstants.ROBOT_DETAILS_SUCCESS,
      payload: robotDetail,
    });
  } catch (error) {}
};

// GET all robots command
// export const getRobotAction = (robotId) => async (dispatch) => {
//   try {
//     dispatch({
//       type: robotConstants.ROBOT_ACTION_REQUEST,
//     });

//     const token = localStorage.getItem("token");

//     const { data } = await axios.post(
//       `${process.env.REACT_APP_BASE_URL}robot/v1/getRobotCommandListByRobotId`,
//       {
//         robotId: robotId,
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//           "Content-Type": "application/json",
//         },
//       }
//     );

//     let robotDetail = {};
//     console.log("robotCommandList", data);

//     robotDetail[robotId] = { robotCommandList: data.robotCommandList };

//     dispatch({
//       type: robotConstants.ROBOT_ACTION_SUCCESS,
//       payload: robotDetail,
//     });
//   } catch (error) {
//     dispatch({
//       type: robotConstants.ROBOT_ACTION_FAIL,
//       payload: error,
//     });
//   }
// };

// GET robot path
// export const getRobotPath = (robotId) => async (dispatch) => {
//   try {
//     dispatch({
//       type: robotConstants.ROBOT_PATH_REQUEST,
//     });

//     const token = localStorage.getItem("token");

//     const { data } = await axios.post(
//       `${process.env.REACT_APP_BASE_URL}robot/v2/getRobotPath`,
//       {
//         robotId: robotId,
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//           "Content-Type": "application/json",
//         },
//       }
//     );

//     let robotDetail = {};

//     robotDetail[robotId] = { path: data.path };

//     dispatch({
//       type: robotConstants.ROBOT_PATH_SUCCESS,
//       payload: robotDetail,
//     });
//   } catch (error) {
//     dispatch({
//       type: robotConstants.ROBOT_PATH_FAIL,
//       payload: error,
//     });
//   }
// };

// ORDER STATUS
export const getOrderStatus = (orderId) => async (dispatch) => {
  try {
    dispatch({
      type: robotConstants.ORDER_DETAILS_REQUEST,
    });

    const token = localStorage.getItem("token");

    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}order/status`,
      {
        orderId: orderId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({
      type: robotConstants.ORDER_DETAILS_SUCCESS,
      payload: data.orderStatus,
    });
  } catch (error) {
    dispatch({
      type: robotConstants.ORDER_DETAILS_FAIL,
      payload: error,
    });
  }
};
export const getRobotDirection =
  (directionsService, origin, destination) => async (dispatch) => {
    try {
      dispatch({
        type: robotConstants.ROBOT_DIRECTIONS_REQUEST,
      });

      const data = await directionsService.route({
        origin: origin,
        destination: destination,
        travelMode: window.google.maps.TravelMode.WALKING,
      });

      if (data.status === window.google.maps.DirectionsStatus.OK) {
        dispatch({
          type: robotConstants.ROBOT_DIRECTIONS_SUCCESS,
          payload: data,
        });
      } else {
        throw new Error(`error fetching directions ${data}`);
      }
    } catch (error) {
      dispatch({
        type: robotConstants.ROBOT_DIRECTIONS_FAIL,
        payload: error,
      });
    }
  };
export const getRobotDirectionbyMapbox =
  (origin, destination) => async (dispatch) => {
    try {
      dispatch({
        type: robotConstants.ROBOT_DIRECTIONS_REQUEST,
      });

      const data = await axios.get(
        `https://api.mapbox.com/directions/v5/mapbox/walking/${origin.lng},${origin.lat};${destination.lng},${destination.lat}?geometries=geojson&access_token=${process.env.REACT_APP_MAPBOXTOKEN}`
      );
      dispatch({
        type: robotConstants.ROBOT_DIRECTIONS_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: robotConstants.ROBOT_DIRECTIONS_FAIL,
        payload: error,
      });
    }
  };
