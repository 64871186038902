import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fleetListAndRobotStatusByUserEmail } from "../redux/actions/fleet";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import DashboardSideBar from "../subcomponents/DashboardSideBar";
import Header from "./Header";
import OwlCarousel from "react-owl-carousel";
import DashboardBottomBar from "./DashboardBottomBar";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import MapboxFleet from "../subcomponents/MapBoxFleet";
import MapboxRobot from "../subcomponents/MapboxRobot";
import { getfleetzones, getgeofenceZone, getnogozones } from "../API";
import { AiFillInfoCircle } from "react-icons/ai";
import { storeRobotDetails } from "../redux/Actions";

function Dashboard(props) {
  const [mapcheck, setmapcheck] = useState(false);
  const [mapchecktwo, setmapchecktwo] = useState(false);
  const [geofencezone, setgeofencezone] = useState();
  const [fleetzones, setfleetzones] = useState();
  const [nogozones, setnogozones] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [batterySwapHover, setBatterySwapHover] = useState(false);
  const { sidebarcollapse } = useSelector((state) => state.login);
  const token = localStorage.getItem("token");
  const emailId = localStorage.getItem("emailId");
  const role = localStorage.getItem("role");

  const { fleetList } = useSelector((state) => state.fleetList);
  const robotDetails = useSelector((state) => state.robotDetailsReducer);

  let activerobot = 0;
  //   let robotlength = 0;

  const updateState = useCallback(async () => {
    dispatch(fleetListAndRobotStatusByUserEmail(emailId, role));
    if (fleetList.length > 0) {
      fleetList.map((fleet) => {
        dispatch(storeRobotDetails(fleet.fleetId));
        return null;
      });
    }
  }, [dispatch, emailId, fleetList]);

  useEffect(() => {
    if (
      !localStorage.getItem("token") ||
      localStorage.getItem("token") === null ||
      localStorage.getItem("token") === "null" ||
      localStorage.getItem("token") === undefined
    ) {
      navigate("/");
      // console.log('token', token)
    }
  }, []);

  const useIntervalAsync = (callbackFunction, intervalInMs) => {
    const timeout = useRef();

    const apiCall = useCallback(async () => {
      await callbackFunction();
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
      timeout.current = window.setTimeout(apiCall, intervalInMs);
    }, [callbackFunction, intervalInMs]);

    useEffect(() => {
      apiCall();
      return () => {
        clearTimeout(timeout.current);
      };
    }, [apiCall]);
  };

  useIntervalAsync(updateState, 5000);

  const getrunningstatuscolor = (robotRunningState) => {
    switch (robotRunningState) {
      case "AUTO_SYSTEM_FAULT":
        return "Dashboard_page_individual_robot_robotstatus_div AutoSystem_fault_error_p";
      case "ERROR":
        return "Dashboard_page_individual_robot_robotstatus_div AutoSystem_fault_error_p";
      case "AUTO_OBSTACLE":
        return "Dashboard_page_individual_robot_robotstatus_div AutoObstacle_teleop_obstacle_p";
      case "AUTO_RUN_OBSTACLE":
        return "Dashboard_page_individual_robot_robotstatus_div Teleop_autoRun_p";
      case "AUTO_RUN_MOVING":
        return "Dashboard_page_individual_robot_robotstatus_div AutoRun_moving_p";
      case "AUTO_RUN":
        return "Dashboard_page_individual_robot_robotstatus_div Teleop_autoRun_p";
      case "TELE_OP_OBSTACLE":
        return "Dashboard_page_individual_robot_robotstatus_div AutoObstacle_teleop_obstacle_p";
      case "TELE_OP_MOVING":
        return "Dashboard_page_individual_robot_robotstatus_div teleopMoving_bordertop_p";
      case "TELE_OP":
        return "Dashboard_page_individual_robot_robotstatus_div Teleop_autoRun_p";
      case "PAYLOAD_STATE":
        return "Dashboard_page_individual_robot_robotstatus_div payloadState_bordertop_p";
      case "MANUAL":
        return "Dashboard_page_individual_robot_robotstatus_div Teleop_autoRun_p";
      default:
        return "Dashboard_page_individual_robot_robotstatus_div defaultBorderTop_P";
    }
  };

  const getrobotStatus = (status) => {
    switch (status) {
      case "UNAVAILABLE":
        return (
          <p className="color-red Dashboard_page_robotStatusClass">
            {" "}
            UNAVAILABLE
          </p>
        );
      case "AVAILABLE":
        return (
          <p className="color-green Dashboard_page_robotStatusClass">
            {" "}
            AVAILABLE
          </p>
        );
      case "ERROR":
        return (
          <p className="color-yellow Dashboard_page_robotStatusClass"> Error</p>
        );
      default:
        return (
          <p className="color-red Dashboard_page_robotStatusClass"> {status}</p>
        );
    }
  };

  const getrobotDot = (status) => {
    switch (status) {
      case "UNAVAILABLE":
        return <div className="red-dot"> </div>;
      case "AVAILABLE":
        return <div className="green-dot"> </div>;
      case "ERROR":
        return <div className="yellow-dot"> </div>;
      default:
        return <div className="red-dot"> </div>;
    }
  };

  const getrobotIcons = (item) => {
    switch (item?.robotStatus) {
      case "UNAVAILABLE":
        return (
          <>
            {/* <img src="/assets/images/CPUred.svg" alt="CPUred_icon" /> */}

            <div className="battery-icon-css">
              <span
                style={{
                  width: "30px",
                  border: "1px solid #fff",
                  borderRadius: "4px",
                  padding: "2px",
                  height: "14px",
                  display: "inline-block",
                }}
              >
                <strong
                  style={{
                    background:
                      parseFloat(item?.robotBattery).toFixed(1) <= 20
                        ? "#E4261C"
                        : "rgb(113, 207, 98)",
                    width: `${
                      parseFloat(item?.robotBattery).toFixed(1) < 0
                        ? 0
                        : parseFloat(item?.robotBattery).toFixed(1)
                    }%`,
                    display: "block",
                    height: "8px",
                    fontWeight: "bold",
                  }}
                ></strong>
              </span>
            </div>
            <p
              style={{
                fontSize: "10px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              {parseFloat(item?.robotBattery).toFixed(0)} %
            </p>

            <div className="Signal_div_wrapper">
              <span
                style={{
                  background: "#fff",
                  width: "3px",
                  height: "3px",
                  display: "inline-block",
                  borderRadius: "3px",
                  marginRight: "3px",
                }}
              ></span>
              <span
                style={{
                  background: "#fff",
                  width: "3px",
                  height: "6px",
                  display: "inline-block",
                  borderRadius: "3px",
                  marginRight: "3px",
                }}
              ></span>
              <span
                style={{
                  background: "#fff",
                  width: "3px",
                  height: "9px",
                  display: "inline-block",
                  borderRadius: "3px",
                  marginRight: "3px",
                }}
              ></span>
              <span
                style={{
                  background: "#fff",
                  width: "3px",
                  height: "12px",
                  display: "inline-block",
                  borderRadius: "3px",
                  marginRight: "3px",
                  opacity: "0.5",
                }}
              ></span>
              <span
                style={{
                  background: "#fff",
                  width: "3px",
                  height: "15px",
                  display: "inline-block",
                  borderRadius: "3px",
                  marginRight: "3px",
                  opacity: "0.5",
                }}
              ></span>
            </div>
            {/* <img src="/assets/images/Bagorange.svg" alt="Bagorange_icon" /> */}
          </>
        );
      case "AVAILABLE":
        return (
          <>
            {/* <img src="/assets/images/CPUYellow.svg" alt="CPUYellow_icon" /> */}

            <div className="battery-icon-css">
              <span
                style={{
                  width: "30px",
                  border: "1px solid #fff",
                  borderRadius: "4px",
                  padding: "2px",
                  height: "14px",
                  display: "inline-block",
                }}
              >
                <strong
                  style={{
                    background:
                      parseFloat(item?.robotBattery).toFixed(1) <= 20
                        ? "#E4261C"
                        : "rgb(113, 207, 98)",
                    width: `${
                      parseFloat(item?.robotBattery).toFixed(1) < 0
                        ? 0
                        : parseFloat(item?.robotBattery).toFixed(1)
                    }%`,
                    display: "block",
                    height: "8px",
                    fontWeight: "bold",
                  }}
                ></strong>
              </span>
            </div>
            <p
              style={{
                fontSize: "10px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              {parseFloat(item?.robotBattery).toFixed(0)} %
            </p>

            <div className="Signal_div_wrapper">
              <span
                style={{
                  background: "#fff",
                  width: "3px",
                  height: "3px",
                  display: "inline-block",
                  borderRadius: "3px",
                  marginRight: "3px",
                }}
              ></span>
              <span
                style={{
                  background: "#fff",
                  width: "3px",
                  height: "6px",
                  display: "inline-block",
                  borderRadius: "3px",
                  marginRight: "3px",
                }}
              ></span>
              <span
                style={{
                  background: "#fff",
                  width: "3px",
                  height: "9px",
                  display: "inline-block",
                  borderRadius: "3px",
                  marginRight: "3px",
                }}
              ></span>
              <span
                style={{
                  background: "#fff",
                  width: "3px",
                  height: "12px",
                  display: "inline-block",
                  borderRadius: "3px",
                  marginRight: "3px",
                  opacity: "0.5",
                }}
              ></span>
              <span
                style={{
                  background: "#fff",
                  width: "3px",
                  height: "15px",
                  display: "inline-block",
                  borderRadius: "3px",
                  marginRight: "3px",
                  opacity: "0.5",
                }}
              ></span>
            </div>
            {/* <img src="/assets/images/Bagyellow.svg" alt="Bagyellow_icon" /> */}
          </>
        );
      case "ERROR":
        return (
          <>
            {/* <img src="/assets/images/CPUorange.svg" alt="CPUorange_icon" /> */}

            <div className="battery-icon-css">
              <span
                style={{
                  width: "30px",
                  border: "1px solid #fff",
                  borderRadius: "4px",
                  padding: "2px",
                  height: "14px",
                  display: "inline-block",
                }}
              >
                <strong
                  style={{
                    background:
                      parseFloat(item?.robotBattery).toFixed(1) <= 20
                        ? "#E4261C"
                        : "rgb(113, 207, 98)",
                    width: `${
                      parseFloat(item?.robotBattery).toFixed(1) < 0
                        ? 0
                        : parseFloat(item?.robotBattery).toFixed(1)
                    }%`,
                    display: "block",
                    height: "8px",
                    fontWeight: "bold",
                  }}
                ></strong>
              </span>
            </div>
            <p
              style={{
                fontSize: "10px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              {parseFloat(item?.robotBattery).toFixed(0)} %
            </p>

            <div className="Signal_div_wrapper">
              <span
                style={{
                  background: "#fff",
                  width: "3px",
                  height: "3px",
                  display: "inline-block",
                  borderRadius: "3px",
                  marginRight: "3px",
                }}
              ></span>
              <span
                style={{
                  background: "#fff",
                  width: "3px",
                  height: "6px",
                  display: "inline-block",
                  borderRadius: "3px",
                  marginRight: "3px",
                }}
              ></span>
              <span
                style={{
                  background: "#fff",
                  width: "3px",
                  height: "9px",
                  display: "inline-block",
                  borderRadius: "3px",
                  marginRight: "3px",
                }}
              ></span>
              <span
                style={{
                  background: "#fff",
                  width: "3px",
                  height: "12px",
                  display: "inline-block",
                  borderRadius: "3px",
                  marginRight: "3px",
                  opacity: "0.5",
                }}
              ></span>
              <span
                style={{
                  background: "#fff",
                  width: "3px",
                  height: "15px",
                  display: "inline-block",
                  borderRadius: "3px",
                  marginRight: "3px",
                  opacity: "0.5",
                }}
              ></span>
            </div>
            {/* <img src="/assets/images/Bagred.svg" alt="bagred_icon" /> */}
          </>
        );
      default:
        return (
          <>
            {/* <img src="/assets/images/CPUred.svg" alt="CPU_icon" /> */}

            <div className="battery-icon-css">
              <span
                style={{
                  width: "30px",
                  border: "1px solid #fff",
                  borderRadius: "4px",
                  padding: "2px",
                  height: "14px",
                  display: "inline-block",
                }}
              >
                <strong
                  style={{
                    background:
                      parseFloat(item?.robotBattery).toFixed(1) <= 20
                        ? "#E4261C"
                        : "rgb(113, 207, 98)",
                    width: `${
                      parseFloat(item?.robotBattery).toFixed(1) < 0
                        ? 0
                        : parseFloat(item?.robotBattery).toFixed(1)
                    }%`,
                    display: "block",
                    height: "8px",
                  }}
                ></strong>
              </span>
            </div>
            <p
              style={{
                fontSize: "10px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              {parseFloat(item?.robotBattery).toFixed(0)} %
            </p>
            <div className="Signal_div_wrapper">
              <span
                style={{
                  background: "#fff",
                  width: "3px",
                  height: "3px",
                  display: "inline-block",
                  borderRadius: "3px",
                  marginRight: "3px",
                }}
              ></span>
              <span
                style={{
                  background: "#fff",
                  width: "3px",
                  height: "6px",
                  display: "inline-block",
                  borderRadius: "3px",
                  marginRight: "3px",
                }}
              ></span>
              <span
                style={{
                  background: "#fff",
                  width: "3px",
                  height: "9px",
                  display: "inline-block",
                  borderRadius: "3px",
                  marginRight: "3px",
                }}
              ></span>
              <span
                style={{
                  background: "#fff",
                  width: "3px",
                  height: "12px",
                  display: "inline-block",
                  borderRadius: "3px",
                  marginRight: "3px",
                  opacity: "0.5",
                }}
              ></span>
              <span
                style={{
                  background: "#fff",
                  width: "3px",
                  height: "15px",
                  display: "inline-block",
                  borderRadius: "3px",
                  marginRight: "3px",
                  opacity: "0.5",
                }}
              ></span>
            </div>
            {/* <img src="/assets/images/Bagred.svg" alt="bagred_icon" /> */}
          </>
        );
    }
  };

  const isopen = useSelector((state) => state.dashboardopenReducer);

  useEffect(() => {
    getgeofenceZone(fleetList[0]?.fleetId, token)
      .then((res) => {
        //  console.log('Geofence Response', res)
        if (!res.data.geofence) {
          setgeofencezone([]);
        }
        setgeofencezone(res.data.geofence);
      })
      .catch((err) => {
        //   console.log("GeoFenceError", err);
      });

    getfleetzones(fleetList[0]?.fleetId, token)
      .then((res) => {
        // console.log('FleetZone Response', res)
        if (!res.data.teleoperationZones) {
          setfleetzones([]);
        }
        setfleetzones(res.data.teleoperationZones);
      })
      .catch((err) => {
        //   console.log("FleetZone errr", err);
      });
    getnogozones(fleetList[0]?.fleetId, token)
      .then((res) => {
        // console.log('NogoZone Response', res)
        if (!res.data.nogoZones) {
          setnogozones([]);
        }
        setnogozones(res.data.nogoZones);
      })
      .catch((err) => {
        //   console.log("NogoZoneERr", err);
      });
  }, [fleetList[0]?.fleetId]);

  const handlerobotClick = (itm) => {
    let latitude;
    let longitude;
    console.log(fleetList);
    fleetList.map((ttt) => {
      if (ttt.fleetId === itm.fleetId) {
        ttt.robots.map((itmmm) => {
          if (itmmm.robotId === itm.robotId) {
            latitude = itmmm.latitude;
            longitude = itmmm.longitude;
          }
        });
      }
    });
    navigate(`/robotPage/${itm.robotId}`, {
      state: {
        data: itm,
        latitude: latitude,
        longitude: longitude,
        robotlistData: fleetList[0].robots,
      },
    });
  };

  //   const handleFleetClick = (fleetList) => {
  //     localStorage.setItem("fleetData", JSON.stringify(fleetList));
  //     navigate(`/fleetPage/${fleetList.fleetName}`, {
  //       state: { data: fleetList },
  //     });
  //   };

  const getdivwrapper = (item) => {
    if (parseFloat(item?.robotBattery).toFixed(1) <= 20) {
      return "Dasboard_page_individual_robot_box_wrapper border_red_battery";
    } else {
      return "Dasboard_page_individual_robot_box_wrapper";
    }
  };

  return (
    <>
      <Header />
      <div className="Dashboard_page_Whole_content_wrapper_main"></div>
      <div className="container-fluid">
        <div className="row">
          <div
            className={sidebarcollapse ? "col-md-2 collapseWidth" : "col-md-2"}
          >
            <DashboardSideBar
              dashboardopen={props.dashboardopen}
              active="dashboard"
            />
            <DashboardBottomBar active="dashboard" />
          </div>
          <div
            className={`${
              sidebarcollapse ? "col-md-10 collapseWidth" : "col-md-10"
            }`}
          >
            <div
              style={{ height: "100%" }}
              className={isopen.userlogindata ? "" : ""}
            >
              <div className="Dashboard_page_rightSide_content_heading">
                {mapcheck ? (
                  <div>
                    <FormGroup className="Dashboard_page_rightSide_content_heading_formgroup">
                      <span
                        className={
                          mapchecktwo
                            ? "Dashboard_page_rightSide_content_heading_span switchcase-active"
                            : null
                        }
                        style={{
                          paddingRight: "20px",
                          color: "#fff",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        Fleets Locations
                      </span>
                      <FormControlLabel
                        style={{ color: "#fff" }}
                        control={
                          <Switch
                            checked={mapchecktwo}
                            style={{ color: "#fff" }}
                            onChange={() => {
                              setmapchecktwo(!mapchecktwo);
                            }}
                          />
                        }
                        label=""
                      />
                      <span
                        className={mapchecktwo ? null : "switchcase-active"}
                        style={{ color: "#fff", fontWeight: "bold" }}
                      >
                        {" "}
                        Robots locations{" "}
                      </span>
                    </FormGroup>
                  </div>
                ) : (
                  <h4 className="Dashboard_page_Robot_Card_heading">
                    {" "}
                    My Fleets
                  </h4>
                )}
              </div>

              {mapcheck ? (
                mapchecktwo && geofencezone && fleetzones && nogozones ? (
                  <MapboxRobot
                    mapchecktwo={mapchecktwo}
                    geofencezone={geofencezone}
                    fleetzones={fleetzones}
                    nogozones={nogozones}
                  />
                ) : (
                  <MapboxFleet mapchecktwo={mapchecktwo} />
                )
              ) : (
                <>
                  <div className="row">
                    <>
                      {fleetList &&
                        fleetList.length > 0 &&
                        fleetList.map((item, index) => {
                          return (
                            <div
                              key={"fleetListItem" + index}
                              className="col-md-4 fleet_div"
                              // onClick={() => handleFleetClick(item)}
                            >
                              <div className="Dasboard_page_individual_fleet_box_wrapper">
                                <h4
                                  className={
                                    activerobot > 0
                                      ? "Dashboard_page_individual_fleet_Heading_Green_h4"
                                      : "Dashboard_page_individual_fleet_Heading_red_h4"
                                  }
                                >
                                  {" "}
                                  {item?.fleetName}
                                </h4>
                                {/* <div className="Dasboard_page_individual_fleet_box_inner_div"> <p> Client </p><p>{item.clientName}</p> </div> */}
                                <div className="Dasboard_page_individual_fleet_box_inner_div">
                                  {" "}
                                  <p> Location </p>
                                  <p>{item?.location}</p>{" "}
                                </div>
                                <div className="Dasboard_page_individual_fleet_box_inner_div">
                                  {" "}
                                  <p> Robots </p>
                                  <p>
                                    {robotDetails &&
                                      Object.values(robotDetails).filter(
                                        (robot) =>
                                          robot?.fleetId === item?.fleetId
                                      ).length}
                                  </p>
                                </div>
                                {/* <div className="Dasboard_page_individual_fleet_box_inner_div">
                                    {" "}
                                    <p> Coordinates </p>
                                    <p>
                                      {parseFloat(item.latitude).toFixed(3)} ,
                                      {parseFloat(item.longitude).toFixed(3)}
                                    </p>{" "}
                                  </div> */}
                                {/* <div className="Dasboard_page_individual_fleet_box_inner_div"> <p> Orders in last 7 days </p><p>{item.totalOrders}</p> </div> */}
                                {/* <div className="Dasboard_page_individual_fleet_box_inner_div">
                                                                    {" "}
                                                                    <p> Status </p>
                                                                    <p>{item.status}</p>{" "}
                                                                </div> */}
                              </div>
                            </div>
                          );
                        })}
                    </>
                  </div>
                  <div className="Dashboard_page_robot_card_heading_wrapper">
                    <h4
                      className="Dashboard_page_Robot_Card_heading"
                      style={{}}
                    >
                      {" "}
                      My Robots
                    </h4>
                  </div>

                  <div className="">
                    <div className="row">
                      <>
                        {robotDetails &&
                        Object.keys(robotDetails).length > 0 ? (
                          <>
                            {Object.keys(robotDetails).map((robot, index) => (
                              <div
                                key={"fleetListDataSet" + index}
                                className="col-md-4"
                                onClick={() =>
                                  handlerobotClick(robotDetails[robot])
                                }
                                onMouseEnter={() =>
                                  parseInt(
                                    robotDetails[robot]?.robotBattery
                                  ).toFixed(1) <= 20 &&
                                  setBatterySwapHover(true)
                                }
                                onMouseLeave={() =>
                                  parseInt(
                                    robotDetails[robot]?.robotBattery
                                  ).toFixed(1) <= 20 &&
                                  setBatterySwapHover(false)
                                }
                              >
                                <div
                                  className={getdivwrapper(robotDetails[robot])}
                                >
                                  <div className="d-flex-center Dasboard_page_individual_robot_box_1">
                                    <p> OTTOBOT </p>
                                    <div
                                      className={getrunningstatuscolor(
                                        robotDetails[robot]?.robotRunningState
                                      )}
                                    >
                                      <span className="state_border">
                                        {" "}
                                        {robotDetails[robot]?.robotRunningState}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="d-flex-center Dasboard_page_individual_robot_box_2">
                                    <div
                                      className="d-flex-center"
                                      style={{ gap: "10px" }}
                                    >
                                      {parseFloat(
                                        robotDetails[robot]?.robotBattery
                                      ).toFixed(1) <= 20 && (
                                        <span className="Battery_swap_span">
                                          <img
                                            src="/assets/images/batteryswa.svg"
                                            alt="battery_swap_icon"
                                          />
                                          <span
                                            style={{
                                              visibility: batterySwapHover
                                                ? "visible"
                                                : "hidden",
                                              zIndex: "1",
                                              position: "absolute",
                                              top: "-4px",
                                              left: "32px",
                                              display: "flex",
                                              justifyContent: "center",
                                              paddingBottom: "2px",
                                              paddingLeft: "2px",
                                              paddingRight: "4px",
                                              width: "max-content",
                                              gap: "2px",
                                              alignItems: "center",
                                              borderRadius: "20px",
                                              backgroundColor: "#fb6262",
                                            }}
                                          >
                                            <AiFillInfoCircle
                                              style={{
                                                color: "white",
                                                width: "13px",
                                                height: "13px",
                                              }}
                                            />
                                            <p
                                              style={{
                                                fontSize: "10px",
                                                fontStyle: "italic",
                                                color: "white",
                                                fontWeight: "300px",
                                                fontFamily: "sans-serif",
                                              }}
                                              className="text-[10px] text-white font-lilita italic font-light"
                                            >
                                              Battery Swap Required
                                            </p>
                                          </span>
                                        </span>
                                      )}
                                      <div className="Dashboard_page_reddot_wrapper">
                                        <h4 className="Dasboard_page_individual_robot_h4">
                                          {" "}
                                          {robotDetails[robot]?.robotId}
                                        </h4>
                                        {getrobotStatus(
                                          robotDetails[robot]?.robotStatus
                                        )}
                                        {getrobotDot(
                                          robotDetails[robot]?.robotStatus
                                        )}
                                      </div>
                                    </div>
                                    <div>
                                      <div className="Dashboard_page_robot_div_icons">
                                        {getrobotIcons(robotDetails[robot])}
                                      </div>
                                      <div>
                                        {" "}
                                        {robotDetails[robot]?.robotStatus ===
                                        "AVAILABLE" ? (
                                          <button className="Pause_btn">
                                            {" "}
                                            Pause{" "}
                                          </button>
                                        ) : (
                                          <button className="Play_btn">
                                            {" "}
                                            Play{" "}
                                          </button>
                                        )}{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </>
                        ) : (
                          <div className="col-md-4">
                            <div className="Dasboard_page_individual_robot_box_wrapper no__robot__available">
                              No Robots Available
                            </div>
                          </div>
                        )}
                      </>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
