import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Maps from "../RobotActions/Maps";
import GoogleTwo from "../subcomponents/GoogleTwo";
import { fleetListAndRobotStatusByUserEmail } from "../redux/actions/fleet";
import MapboxRobot from "../subcomponents/MapboxRobot";
import { getfleetzones, getgeofenceZone, getnogozones } from "../API";
import { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Accordion from "react-bootstrap/Accordion";
import { useRef } from "react";
import { useCallback } from "react";
import { storeRobotDetails } from "../redux/actions/robot";
function FleetPagerobotPage(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [geofencezone, setgeofencezone] = useState();
  const [fleetzones, setfleetzones] = useState();
  const [nogozones, setnogozones] = useState();
  const { fleetList } = useSelector((state) => state.fleetList);
  const emailId = localStorage.getItem("useremail");
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("token");
  const robotDetails = useSelector((state) => state.robotDetailsReducer);
  const [collapsebar, setcollapsebar] = useState(true);
  const handlerobotClick = (itm) => {
    let latitude;
    let longitude;
    props?.robotlistData?.map((ttt) => {
      if (ttt?.fleetId === itm?.fleetId) {
        ttt.robots.map((itmmm) => {
          if (itmmm.robotId === itm.robotId) {
            latitude = itmmm.latitude;
            longitude = itmmm.longitude;
          }
        });
      }
    });

    navigate(`/robotPage/${itm.robotId}`, {
      state: {
        data: itm,
        latitude: latitude,
        longitude: longitude,
        robotlistData: fleetList[0].robots,
      },
    });
  };

  // useEffect(() => {
  //   if (fleetList.length === 0) {
  //     dispatch(fleetListAndRobotStatusByUserEmail(emailId));
  //   }
  // }, [fleetList, dispatch, emailId])
  const updateState = useCallback(async () => {
    let robotIdfromURL = window.location.pathname.substring(
      window.location.pathname.lastIndexOf("/") + 1
    );

    dispatch(fleetListAndRobotStatusByUserEmail(emailId, role));
    if (fleetList.length > 0) {
      fleetList.map((fleet) => {
        dispatch(storeRobotDetails(fleet.fleetId));
        return null;
      });
    }
  }, [dispatch, emailId]);

  const useIntervalAsync = (callbackFunction, intervalInMs) => {
    const timeout = useRef();

    const apiCall = useCallback(async () => {
      await callbackFunction();
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
      timeout.current = window.setTimeout(apiCall, intervalInMs);
    }, [callbackFunction, intervalInMs]);

    useEffect(() => {
      apiCall();
      return () => {
        clearTimeout(timeout.current);
      };
    }, [apiCall]);
  };

  useIntervalAsync(updateState, 2000);

  useEffect(() => {
    if (
      token === "" ||
      token === "null" ||
      token === null ||
      token === undefined
    ) {
      window.location.replace("/");
    }

    getgeofenceZone(props?.fleetData?.fleetId, token)
      .then((res) => {
        //  console.log('Geofence Response', res)
        if (!res.data.geofence) {
          setgeofencezone([]);
        }
        setgeofencezone(res.data.geofence);
      })
      .catch((err) => {
        console.log("GeoFenceError", err);
      });

    getfleetzones(props?.fleetData?.fleetId, token)
      .then((res) => {
        // console.log('FleetZone Response', res)
        if (!res.data.teleoperationZones) {
          setfleetzones([]);
        }
        setfleetzones(res.data.teleoperationZones);
      })
      .catch((err) => {
        console.log("FleetZone errr", err);
      });
    getnogozones(props?.fleetData?.fleetId, token)
      .then((res) => {
        // console.log('NogoZone Response', res)
        if (!res.data.nogoZones) {
          setnogozones([]);
        }
        setnogozones(res.data.nogoZones);
      })
      .catch((err) => {
        console.log("NogoZoneERr", err);
      });
  }, [props?.fleetData?.fleetId, collapsebar]);

  const getrunningstatuscolor = (robotRunningState) => {
    switch (robotRunningState) {
      case "AUTO_SYSTEM_FAULT":
        return "Dashboard_page_individual_robot_robotstatus_div AutoSystem_fault_error_p";
      case "ERROR":
        return "Dashboard_page_individual_robot_robotstatus_div AutoSystem_fault_error_p";
      case "AUTO_OBSTACLE":
        return "Dashboard_page_individual_robot_robotstatus_div AutoObstacle_teleop_obstacle_p";
      case "AUTO_RUN_OBSTACLE":
        return "Dashboard_page_individual_robot_robotstatus_div Teleop_autoRun_p";
      case "AUTO_RUN_MOVING":
        return "Dashboard_page_individual_robot_robotstatus_div AutoRun_moving_p";
      case "AUTO_RUN":
        return "Dashboard_page_individual_robot_robotstatus_div Teleop_autoRun_p";
      case "TELE_OP_OBSTACLE":
        return "Dashboard_page_individual_robot_robotstatus_div AutoObstacle_teleop_obstacle_p";
      case "TELE_OP_MOVING":
        return "Dashboard_page_individual_robot_robotstatus_div teleopMoving_bordertop_p";
      case "TELE_OP":
        return "Dashboard_page_individual_robot_robotstatus_div Teleop_autoRun_p";
      case "PAYLOAD_STATE":
        return "Dashboard_page_individual_robot_robotstatus_div payloadState_bordertop_p";
      case "MANUAL":
        return "Dashboard_page_individual_robot_robotstatus_div Teleop_autoRun_p";
      default:
        return "Dashboard_page_individual_robot_robotstatus_div defaultBorderTop_P";
    }
  };
  return (
    <>
      {props?.fleetData?.length !== 0 && (
        <div className="Individual_fleet_page_map_wrapper">
          {/* Google Map ROBOTS OPTION */}
          {props.googlemap && geofencezone && fleetzones && nogozones ? (
            // <GoogleTwo
            //   isMarkerShown
            //   googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBRQzEYlLWkb-swa-hIj5YW9F1Kz-ixg0M&v=3.exp&libraries=geometry,drawing,places"
            //   loadingElement={<div style={{ height: `100%` }} />}
            //   containerElement={<div style={{ height: "70vh" }} />}
            //   mapElement={<div style={{ height: "70vh" }} />}
            //   mapchecktwo={true}
            //   ApiKey={"AIzaSyBRQzEYlLWkb-swa-hIj5YW9F1Kz-ixg0M"}
            // />
            <MapboxRobot
              fleetData={props?.fleetData}
              geofencezone={geofencezone}
              fleetzones={fleetzones}
              nogozones={nogozones}
            />
          ) : (
            // <Maps
            //   fleetId={props?.fleetData?.fleetId}
            //   robotlistData={props?.fleetData?.robots}
            // />
            <div style={{ textAlign: "center" }}>
              <CircularProgress sx={{ color: "white" }} />
            </div>
          )}
          {robotDetails && Object.values(robotDetails).length > 0 && (
            <div className="Fleetrobotpage_popup_wrapper">
              <div
                className={
                  collapsebar
                    ? "collapse_side_bar_class Collapse_transition_Class"
                    : "collapse_side_bar_class_width Collapse_transition_Class"
                }
              >
                <table>
                  <thead> </thead>
                  <tbody>
                    {robotDetails &&
                      Object.values(robotDetails).length > 0 &&
                      Object.values(robotDetails).map((item, key) => {
                        return (
                          <tr key={key}>
                            <td className="d-flex-center">
                              {" "}
                              <p
                                className="SelectFleetPage-inner-li-div-robot-id robotId_p"
                                style={{
                                  cursor: "pointer",
                                  color: "white",
                                  position: "relative",
                                }}
                                onClick={() => handlerobotClick(item)}
                              >
                                {item.robotId}
                                {/* <sup className={ item.connectionStatus === "OFFLINE" ? "p-color-red-two": "p-color-green-two"} >
                           {item.connectionStatus}
                         </sup>
                       {item.connectionStatus === "OFFLINE" ? (
                       <span style={{ fontSize: "12px" }}>
                         {" "}
                        {item.lastUpdatedTime}
                      </span>
                    ) : null} */}
                              </p>
                              <p className={`color-class-${key + 1}`}> </p>
                            </td>
                            <td>
                              <div className="border_right d-flex-start">
                                <div className="battery-icon-css_three_collapse">
                                  <span className="Battery_inner_wrapper">
                                    <strong
                                      style={{
                                        background:
                                          parseInt(item?.robotBattery).toFixed(
                                            0
                                          ) <= 20
                                            ? "#E4261C"
                                            : "rgb(113, 207, 98)",
                                        width: `${
                                          parseFloat(
                                            item?.robotBattery
                                          ).toFixed(1) < 0
                                            ? 0
                                            : parseFloat(
                                                item?.robotBattery
                                              ).toFixed(1)
                                        }%`,
                                        display: "block",
                                        height: "6px",
                                      }}
                                    ></strong>
                                    <span> </span>
                                  </span>
                                </div>
                                <p className="Battery_percent">
                                  {parseInt(item?.robotBattery)}%{" "}
                                </p>
                                <div>
                                  <span
                                    style={{
                                      background: "#fff",
                                      width: "3px",
                                      height: "4px",
                                      display: "inline-block",
                                      borderRadius: "3px",
                                      marginRight: "3px",
                                      padding: "0px",
                                    }}
                                  ></span>
                                  <span
                                    style={{
                                      background: "#fff",
                                      width: "3px",
                                      height: "8px",
                                      display: "inline-block",
                                      borderRadius: "3px",
                                      marginRight: "3px",
                                      padding: "0px",
                                    }}
                                  ></span>
                                  <span
                                    style={{
                                      background: "#fff",
                                      width: "3px",
                                      height: "12px",
                                      display: "inline-block",
                                      borderRadius: "3px",
                                      marginRight: "3px",
                                      padding: "0px",
                                    }}
                                  ></span>
                                  <span
                                    style={{
                                      background: "#fff",
                                      width: "3px",
                                      height: "16px",
                                      display: "inline-block",
                                      borderRadius: "3px",
                                      marginRight: "3px",
                                      opacity: "0.5",
                                      padding: "0px",
                                    }}
                                  ></span>
                                  <span
                                    style={{
                                      background: "#fff",
                                      width: "3px",
                                      height: "20px",
                                      display: "inline-block",
                                      borderRadius: "3px",
                                      marginRight: "3px",
                                      opacity: "0.5",
                                      padding: "0px",
                                    }}
                                  ></span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="border_right">
                                <p
                                  className={
                                    item.robotStatus === "UNAVAILABLE"
                                      ? "p-color-red"
                                      : "p-color-green"
                                  }
                                >
                                  {item.robotStatus}
                                </p>
                              </div>
                            </td>
                            <td>
                              <div className="border_right">
                                Coordinates :{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {" "}
                                  {item?.latitude}, {item?.longitude}
                                </span>
                              </div>
                            </td>
                            <td className="d-flex-center">
                              <div
                                style={{ marginRight: "35px" }}
                                className={getrunningstatuscolor(
                                  item?.robotRunningState
                                )}
                              >
                                <span className="state_border">
                                  {" "}
                                  {item?.robotRunningState}
                                </span>
                              </div>
                              <div>
                                {" "}
                                {item.robotStatus === "AVAILABLE" ? (
                                  <p className="p-color-red collapse_p">
                                    {" "}
                                    Pause{" "}
                                  </p>
                                ) : (
                                  <p className="p-color-green collapse_p">
                                    {" "}
                                    Play{" "}
                                  </p>
                                )}{" "}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <button
                className="Collapse_btn"
                onClick={() => {
                  setcollapsebar(!collapsebar);
                }}
              >
                {" "}
                {collapsebar ? (
                  <KeyboardDoubleArrowRightIcon />
                ) : (
                  <KeyboardDoubleArrowLeftIcon />
                )}
              </button>
            </div>
          )}
          <div className="Fleetrobotpage_popup_wrapper_two">
            {robotDetails &&
              Object.values(robotDetails).length > 0 &&
              Object.values(robotDetails) && (
                <Accordion defaultActiveKey="0">
                  {robotDetails &&
                    Object.values(robotDetails).length > 0 &&
                    Object.values(robotDetails).map((item, key) => {
                      return (
                        <Accordion.Item eventKey={key}>
                          <Accordion.Header>
                            <p
                              className="SelectFleetPage-inner-li-div-robot-id robotId_p"
                              style={{ cursor: "pointer", color: "white" }}
                            >
                              {item.robotId}{" "}
                            </p>
                            <p
                              className={`color-class-${key + 1}`}
                              style={{ marginLeft: "10px" }}
                            >
                              {" "}
                            </p>
                          </Accordion.Header>
                          <Accordion.Body
                            onClick={() => handlerobotClick(item)}
                          >
                            <div className="d-flex-center margin_top_bottom">
                              <div className=" d-flex-start">
                                <div className="battery-icon-css_three_collapse">
                                  <span className="Battery_inner_wrapper">
                                    <strong
                                      style={{
                                        background:
                                          parseInt(item.robotBattery).toFixed(
                                            0
                                          ) <= 20
                                            ? "#E4261C"
                                            : "rgb(113, 207, 98)",
                                        width: `${
                                          parseFloat(item.robotBattery).toFixed(
                                            1
                                          ) < 0
                                            ? 0
                                            : parseFloat(
                                                item.robotBattery
                                              ).toFixed(1)
                                        }%`,
                                        display: "block",
                                        height: "6px",
                                      }}
                                    ></strong>
                                    <span> </span>
                                  </span>
                                </div>
                                <p className="Battery_percent">
                                  {parseInt(item.robotBattery)}%{" "}
                                </p>
                                <div>
                                  <span
                                    style={{
                                      background: "#fff",
                                      width: "3px",
                                      height: "4px",
                                      display: "inline-block",
                                      borderRadius: "3px",
                                      marginRight: "3px",
                                      padding: "0px",
                                    }}
                                  ></span>
                                  <span
                                    style={{
                                      background: "#fff",
                                      width: "3px",
                                      height: "8px",
                                      display: "inline-block",
                                      borderRadius: "3px",
                                      marginRight: "3px",
                                      padding: "0px",
                                    }}
                                  ></span>
                                  <span
                                    style={{
                                      background: "#fff",
                                      width: "3px",
                                      height: "12px",
                                      display: "inline-block",
                                      borderRadius: "3px",
                                      marginRight: "3px",
                                      padding: "0px",
                                    }}
                                  ></span>
                                  <span
                                    style={{
                                      background: "#fff",
                                      width: "3px",
                                      height: "16px",
                                      display: "inline-block",
                                      borderRadius: "3px",
                                      marginRight: "3px",
                                      opacity: "0.5",
                                      padding: "0px",
                                    }}
                                  ></span>
                                  <span
                                    style={{
                                      background: "#fff",
                                      width: "3px",
                                      height: "20px",
                                      display: "inline-block",
                                      borderRadius: "3px",
                                      marginRight: "3px",
                                      opacity: "0.5",
                                      padding: "0px",
                                    }}
                                  ></span>
                                </div>
                              </div>
                              <div className="">
                                <p
                                  className={
                                    item.robotStatus === "UNAVAILABLE"
                                      ? "p-color-red"
                                      : "p-color-green"
                                  }
                                >
                                  {item.robotStatus}
                                </p>
                              </div>
                            </div>

                            <div className="d-flex-center margin_top_bottom">
                              <div> Coordinates : </div>
                              <span style={{ fontWeight: "bold" }}>
                                {" "}
                                {item?.latitude}, {item?.longitude}
                              </span>
                            </div>
                            <div className="d-flex-center margin_top_bottom">
                              <div
                                style={{ marginRight: "35px" }}
                                className={getrunningstatuscolor(
                                  item?.robotRunningState
                                )}
                              >
                                <span className="state_border">
                                  {" "}
                                  {item?.robotRunningState}
                                </span>
                              </div>
                              <div>
                                {" "}
                                {item.robotStatus === "AVAILABLE" ? (
                                  <p className="p-color-red collapse_p">
                                    {" "}
                                    Pause{" "}
                                  </p>
                                ) : (
                                  <p className="p-color-green collapse_p">
                                    {" "}
                                    Play{" "}
                                  </p>
                                )}{" "}
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })}
                </Accordion>
              )}
          </div>
        </div>
      )}
    </>
  );
}

export default FleetPagerobotPage;
