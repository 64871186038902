import { fleetConstants } from "../constants/fleet";

export const getFleetListAndRobotStatusByUserEmailReducer = (
  state = { fleetList: [], markers: [] },
  action
) => {
  switch (action.type) {
    case fleetConstants.FLEET_LIST_AND_ROBOT_STATUS_BY_USER_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case fleetConstants.FLEET_LIST_AND_ROBOT_STATUS_BY_USER_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        fleetList: action.payload.fleetData,
        markers: action.payload.markers,
      };

    case fleetConstants.FLEET_LIST_AND_ROBOT_STATUS_BY_USER_EMAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case fleetConstants.CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
