import axios from "axios";
import { robotConstants } from "../constants/robot";

// GET all robots details
export const storeRobotDetails = (fleetId) => async (dispatch) => {
  try {
    dispatch({
      type: robotConstants.ROBOT_DETAILS_REQUEST,
    });

    const token = localStorage.getItem("token");

    let { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}robot/v1/getRobotStatusByFleetId`,
      {
        fleetId: fleetId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (data) {
      let robotDetail = {};

      data.data.forEach((robot) => {
        robotDetail[robot[0].robotId] = robot[0];
      });
      dispatch({
        type: robotConstants.ROBOT_DETAILS_SUCCESS,
        payload: robotDetail,
      });
    }
  } catch (error) {
    dispatch({
      type: robotConstants.ROBOT_DETAILS_FAIL,
      // payload: error,
    });
  }
};

// GET all robots details
export const getRobotDetail = (robotId) => async (dispatch) => {
  try {
    dispatch({
      type: robotConstants.ROBOT_DETAILS_REQUEST,
    });

    const token = localStorage.getItem("token");

    const { data: robotStatusData } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}robot/v1/getRobotStatus`,
      {
        robotId: robotId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const { data: robotCommandData } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}robot/v1/getRobotCommandListByRobotId`,
      {
        robotId: robotId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    const { data: robotPathData } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}robot/v2/getRobotPath`,
      {
        robotId: robotId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    let robotDetail = {};

    robotDetail[robotId] = robotStatusData.data.robotStatus;
    robotDetail[robotId].robotCommandList = robotCommandData.robotCommandList;
    robotDetail[robotId].path = robotPathData.path;

    dispatch({
      type: robotConstants.ROBOT_DETAILS_SUCCESS,
      payload: robotDetail,
    });
  } catch (error) {
    dispatch({
      type: robotConstants.ROBOT_DETAILS_FAIL,
      payload: error,
    });
  }
};

export const destroySession = () => (dispatch) => {
  try {
    dispatch({
      type: robotConstants.DESTROY_SESSION,
    });
  } catch (error) {
    dispatch({
      type: robotConstants.ROBOT_DETAILS_FAIL,
      payload: error,
    });
  }
};
